define('frontend/config/environment', [], function() {
  if (typeof FastBoot !== 'undefined') {
return FastBoot.config('frontend');
} else {

          var exports = {
            'default': {"modulePrefix":"frontend","environment":"production","deployEnv":"production","rootURL":"/","locationType":"history","EmberENV":{"EXTEND_PROTOTYPES":false,"FEATURES":{"EMBER_METAL_TRACKED_PROPERTIES":true},"_APPLICATION_TEMPLATE_WRAPPER":false,"_DEFAULT_ASYNC_OBSERVERS":true,"_JQUERY_INTEGRATION":false,"_NO_IMPLICIT_ROUTE_MODEL":true,"_TEMPLATE_ONLY_GLIMMER_COMPONENTS":true},"APP":{"name":"frontend","version":"0.0.0"},"deliveryCostThreshold":1000,"przelewy24PaymentFee":0.015,"ember-google-maps":{"key":"AIzaSyDQs1OD-iA5exMSqrzsPlO5oPAamkm68xU","language":"pl","region":"PL","protocol":"https","version":"quarterly","src":"https://maps.googleapis.com/maps/api/js?v=quarterly&region=PL&language=pl&key=AIzaSyDQs1OD-iA5exMSqrzsPlO5oPAamkm68xU"},"metricsAdapters":[{"name":"GoogleAnalytics","environments":["development","production"],"config":{"id":"UA-8395333-1","trace":false,"sendHitTask":true}}],"fastboot":{"hostWhitelist":[{}]},"sentry":{"environment":"production","whitelistUrls":["taniaplytka"],"dsn":"https://9d3558b2273f48c6be9d45694cbdd466@sentry.io/226443"},"ember-local-storage":{"loadInitializer":false},"screens":{"sm":630,"md":768,"lg":992},"hostURL":"https://taniaplytka.pl","api":{"host":"https://api.taniaplytka.pl"},"przelewy24URL":"https://secure.przelewy24.pl/trnRequest","ember-cli-mirage":{"usingProxy":false,"useDefaultPassthroughs":true}}
          };
          Object.defineProperty(exports, '__esModule', {value: true});
          return exports;
        
}
});
